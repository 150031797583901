$mdc-theme-primary: #2196f3;
$mdc-theme-primary-light:#6ec6ff;
$mdc-theme-primary-dark:#0069c0;

$grey-text: #5f6368;
$grey-bg: #424242;
$error-text: #b71c1c; 

$mdc-theme-secondary: #76ff03;
$mdc-theme-on-primary: #ffffff;
$mdc-theme-on-secondary: #ffffff;

@import "@material/theme/mdc-theme";
@import "@material/typography/mdc-typography";
@import "@material/button/mdc-button";
@import "@material/textfield/mdc-text-field";
@import "@material/top-app-bar/mdc-top-app-bar";
@import "@material/drawer/mdc-drawer";
@import "@material/list/mdc-list";
@import "@material/card/mdc-card";
@import "@material/layout-grid/mdc-layout-grid";
@import "@material/elevation/mdc-elevation";

@mixin linx ($normal, $hover) {
  a {
    color: $normal;
    &:visited {
      color: $normal;
    }
    &:hover {
      color: $hover;   
    }
    &:active {
      color: $normal;
    }
  }
}

.top-app-bar-logo {
  height: 2rem;
  padding-left: 10px !important;
}

html, body {  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
/*  align-items: center;*/
  margin: 0;
  @include mdc-typography-base;
}

.hidden {
  display: none;
}

.light-text {
  color: $grey-text;
}
.error-text {
  color: $error-text;
}

.main-section {
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.hero-section {
  padding-top: 140px;
  padding-bottom: 20px;
}
.hero-title {
  margin: 0px;
  font-weight: 500;
}
.hero-subtitle {
  margin: 40px;
}
.hero-actions {
  text-align: center;
}
.hero-button {
  margin: 5px;
}

.service-section {
  padding-top: 60px;
  padding-bottom: 60px;
}
.service-offering {
  margin-top: 20px;
  text-align: center;
}
.service-logo-wrapper {
  height: 80px;
  min-width: 180px;
  margin: 0;
  padding: 0;
}
.service-logo {
  height: 100%;
}

.testimonials-section {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: $mdc-theme-primary;
  color: $mdc-theme-on-primary;
}
.testimonial-title {
  font-weight: 450;
  margin-bottom: 0;
}
.testimonial-subtitle {
  margin-top: 0;
  margin-bottom: 1em;
}
.testimonial {
  text-align: center;
  padding: 1vw;
}
.testimonial p {
  text-align: justify;
}

.projects-section {
  margin-top: 60px;
  margin-bottom: 60px;
}
.project-client-img {
  max-width: 80%;
  max-height: 50px;
}
.project-client-img-inline {
  float: left;
  margin: 5px 15px 15px 5px;
  width: 75px;
}
.project-quote {
  font-style: italic;
}

/* Contact Page */
.contact-section {
  margin-top: 80px;
}
#contact-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
}

/* Blog Top-Level Page */
.bloglist-section {
  margin-top: 80px;
  padding-left: 10vw;
  padding-right: 10vw;
}
.bloglist-title {
  margin-bottom: 5px;
}
.bloglist-description {
}
.bloglist-post {
  @include linx($mdc-theme-primary, $mdc-theme-primary-light);
  margin-bottom: 20px;
  text-align: center;
}

/* Blog Post */
.blogpost-section {
  @include linx($mdc-theme-primary, $mdc-theme-primary-light);
  margin-top: 120px;
  padding-left: 10vw;
  padding-right: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.blogpost-title {
  margin-bottom: 0;
}
.blogpost-authordate {
  margin-top: 0;
}
.blogpost-section img {
  max-width: 100%;
}

/* Privacy */
.privacy-section {
  margin-top: 120px;
}

/* Common */
.headline {
  font-weight: 450;
}

.footer-spacer {
  height: 40vh;
}
.footer-filler {
  height: 80px;
}
.footer {
  height: 200px;
  background-color: $grey-bg;
}
.footer-links {
  a {
    display: block;
  }
  text-align: center;
  @include linx($grey-text, white);
}
.footer-copy {
  margin: 20px;
  text-align: center;
  color: $grey-text;
}

.mdc-top-app-bar {
  @include mdc-top-app-bar-fill-color(#ffffff);
  @include mdc-top-app-bar-ink-color(#000000);
  @include mdc-top-app-bar-icon-ink-color(#000000);
}

.mdc-text-field {
  margin-bottom: 24px;
  flex-shrink: 0;
}

#submit-button {
  margin-top: 48px; 
}
